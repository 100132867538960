import { useEffect, useState } from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import { withLocale } from '../../contexts/locale.context'
import SignupStep from '../steps/SignupStep'
import EmailStep from '../steps/EmailStep'
import { getProfile } from '../../helpers/profile'
import { useUserInfos } from './hooks/useUserInfos'
import { confirmInvitation } from '../../api/userSignUp'
import Loader from '../common/Loader'
import $ from './SignupPage.module.scss'
import SimplifiedFlowStep from '../steps/simplified-flow/SimplifiedFlowStep'
import ShopifySelectWorkspace from '../steps/simplified-flow/ShopifySelectWorkspace'
import CreatingWorkspaceStep from '../steps/simplified-flow/CreatingWorkspaceStep'

const MainPage = () => {
	const { path } = useRouteMatch()
	const [, setCurrentStep] = useState(0)

	const matchBaseRegister = useRouteMatch('/register')
	const matchBase = useRouteMatch('/signup')
	const matchEmail = useRouteMatch('/signup/email')
	useEffect(() => {
		if (
			matchBase?.isExact ||
			matchBaseRegister?.isExact ||
			matchEmail?.isExact
		) {
			localStorage.removeItem('profile')
		}
	}, [])

	const [invitationId, setInvitationId] = useState(null)
	useEffect(() => {
		const search = window.location.search.substring(1)
		const searchParams = new URLSearchParams(search)
		const invitationIdParam = searchParams.get('invitation_id')
		setInvitationId(invitationIdParam)
		if (invitationIdParam) {
			localStorage.setItem('invitation_id', invitationIdParam)
			confirmInvitation(invitationIdParam)
		}
	}, [])

	const profile = getProfile()
	const { userInfos, userInfosState } = useUserInfos()

	return (
		<Switch>
			<Route exact path={path}>
				<SignupStep
					setCurrentStep={setCurrentStep}
					invitationId={invitationId}
				/>
			</Route>
			<Route path={`${path}/email`}>
				<EmailStep
					setCurrentStep={setCurrentStep}
					invitationId={invitationId}
				/>
			</Route>
			{profile?.access_token && !userInfosState.isError ? (
				userInfos ? (
					userInfos.signup_step ? (
						<>
							<Route
								path={`${path}/setup`}
								render={() => {
									return (
										<SimplifiedFlowStep
											setCurrentStep={setCurrentStep}
											userInfos={userInfos}
											basePath={path}
										/>
									)
								}}
							/>
							<Route
								path={`${path}/shopify_select_wks`}
								render={() => {
									return (
										<ShopifySelectWorkspace
											setCurrentStep={setCurrentStep}
											userInfos={userInfos}
											basePath={path}
										/>
									)
								}}
							/>
							<Route
								path={`${path}/creating_workspace`}
								render={() => {
									return (
										<CreatingWorkspaceStep
											setCurrentStep={setCurrentStep}
											userInfos={userInfos}
											basePath={path}
										/>
									)
								}}
							/>
							<Route
								path={`${path}/ask_for_access`}
								render={() => {
									return (
										<div>
											Please request permission from the workspace admin to
											connect
										</div>
									)
								}}
							/>
						</>
					) : (
						<Route>
							<Redirect to="/login" />
						</Route>
					)
				) : (
					<Route>
						<div className={$.loader}>
							<Loader color="#ffffff" size={30} />
						</div>
					</Route>
				)
			) : (
				<Route>
					<Redirect to={path} />
				</Route>
			)}
		</Switch>
	)
}

export default withLocale(MainPage)
