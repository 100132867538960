import { useMutation, useQuery } from 'react-query'
import { withLocale } from '../../../contexts/locale.context'
import $BasePage from '../../page/BaseCssPage.module.scss'
import { getWorkspaces } from '../../../api/workspaces'
import $$ from '../../page/SignupPage.module.scss'
import Loader from '../../common/Loader'
import {
	cancelShopifyInstallation,
	createNewWorkspaceForShopifyInstallation,
	refreshInfos,
	selectWorkspaceForShopifyInstallation,
} from '../../../api/userSignUp'
import Button from '../../common/Button'
import { useState } from 'react'
import { getDashboardUrlWithRedirectParams } from '../../../platform'
import { useHistory } from 'react-router-dom'
import { manageSigninOrSignup } from '../../page/helpers/login'

const ShopifySelectWorkspace = ({ lang, userInfos }) => {
	const workspacesQuery = useQuery('workspaces', () =>
		getWorkspaces(userInfos.access_token).then((response) => response.json()),
	)

	// manageSigninOrSignup
	const history = useHistory()

	const selectWorkspaceMutation = useMutation(
		(workspaceId) => selectWorkspaceForShopifyInstallation(workspaceId),
		{
			onSuccess: async ({ workspace_name }) => {
				location.replace(getDashboardUrlWithRedirectParams(workspace_name))
				const infos = await refreshInfos()
				manageSigninOrSignup(infos, history)
			},
		},
	)
	const createNewWorkspaceMutation = useMutation(
		() => createNewWorkspaceForShopifyInstallation(),
		{
			onSuccess: async () => {
				const infos = await refreshInfos()
				manageSigninOrSignup(infos, history)
			},
		},
	)
	const cancelShopifyInstallationMutation = useMutation(
		() => cancelShopifyInstallation(),
		{
			onSuccess: () => {
				const tenantToRedirect = userInfos?.last_tenant_used

				if (tenantToRedirect) {
					location.replace(getDashboardUrlWithRedirectParams(tenantToRedirect))
				} else {
					history.replace('/login?error=no_redirect_tenant_found')
				}
			},
		},
	)

	const workspaces =
		workspacesQuery.data?.filter(
			(w) =>
				w.shopify_domain === undefined ||
				w.shopify_domain === null ||
				w.shopify_domain === '' ||
				w.shopify_domain === '0',
		) ?? []

	const [selectedWorkspace, setSelectedWorkspace] = useState(null)

	return (
		<div className={$BasePage.pageContent}>
			{workspacesQuery.isLoading ? (
				<div className={$$.loader}>
					<Loader color="#ffffff" size={30} />
				</div>
			) : (
				<>
					<div className={$BasePage.titleContainer}>
						<h1>Select a workspace for your Shopify store</h1>
					</div>
					<div className={$BasePage.formContainer}>
						{workspaces.map((d) => (
							<div key={d.id} style={{ marginBottom: '10px' }}>
								<Button
									loading={
										selectWorkspaceMutation.isLoading &&
										selectedWorkspace === d.id
									}
									disabled={
										createNewWorkspaceMutation.isLoading ||
										(selectedWorkspace !== d.id &&
											selectedWorkspace !== null) ||
										cancelShopifyInstallationMutation.isLoading
									}
									onClick={() => {
										setSelectedWorkspace(d.id)
										selectWorkspaceMutation.mutate(d.workspace_id)
									}}
								>
									{d.id}
									{d.instagram_account_name
										? ` @${d.instagram_account_name}`
										: ''}
								</Button>
							</div>
						))}
						<div>
							<Button
								variant="white"
								loading={createNewWorkspaceMutation.isLoading}
								onClick={() => createNewWorkspaceMutation.mutate()}
								disabled={
									selectWorkspaceMutation.isLoading ||
									cancelShopifyInstallationMutation.isLoading
								}
							>
								Install on new workspace
							</Button>
						</div>
						<div>
							<button
								className={$$.cancel}
								loading={cancelShopifyInstallationMutation.isLoading}
								onClick={() => cancelShopifyInstallationMutation.mutate()}
								disabled={
									selectWorkspaceMutation.isLoading ||
									createNewWorkspaceMutation.isLoading
								}
							>
								Or click here to cancel the installation
							</button>
						</div>
					</div>
				</>
			)}
		</div>
	)
}

export default withLocale(ShopifySelectWorkspace)
