import axios from 'axios'
import { getApiHost } from '../platform'
import { getProfile } from './profile'

export const apiWithoutAuth = async (url, options = {}) => {
	const apiHost = getApiHost()
	const config = {
		method: 'GET',
		url: `${apiHost}/${url}`,
		validateStatus: (status) => status >= 200 && status < 400,
		...options,
		headers: {
			'X-Socket-ID': window.echo ? window.echo.connector.socket.id : null,
			...options.headers,
		},
	}
	return (await axios.request(config)).data
}

export const apiWithAuth = async (url, options = {}) => {
	const profile = getProfile()
	const apiHost = getApiHost()
	if (profile) {
		const config = {
			method: 'GET',
			url: `${apiHost}/api/${url}`,
			validateStatus: (status) => status >= 200 && status < 400,
			...options,
			headers: {
				Authorization: `${profile.token_type} ${profile.access_token}`,
				'X-Socket-ID': window.echo ? window.echo.connector.socket.id : null,
				...options.headers,
			},
		}
		return (await axios.request(config)).data
	} else {
		return Promise.reject('No profile')
	}
}
