function Email() {
	return (
		<svg
			width="23"
			height="18"
			viewBox="0 0 23 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21.5 3C21.5 1.9 20.6 1 19.5 1H3.5C2.4 1 1.5 1.9 1.5 3M21.5 3V15C21.5 16.1 20.6 17 19.5 17H3.5C2.4 17 1.5 16.1 1.5 15V3M21.5 3L11.5 10L1.5 3"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export default Email
