import cn from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { loginUser } from '../../api/userLogin'
import EyeClosed from '../../assets/icons/eyeclosed'
import EyeOpened from '../../assets/icons/eyeopened'
import SigninFacebookImage from '../../assets/images/login_with_facebook.png'
import SigninGoogleImage from '../../assets/images/signin_google.png'
import { withLocale } from '../../contexts/locale.context'
import { validateEmail } from '../../helpers/formTests'
import { getApiHost } from '../../platform'
import Button from '../common/Button'
import Input from '../common/Input'
import $ from './Login.module.scss'
import { manageSigninOrSignup } from './helpers/login'
import $BasePage from './BaseCssPage.module.scss'

const LoginPage = ({ lang }) => {
	const history = useHistory()
	const queryClient = useQueryClient()
	const forgotPasswordText = useMemo(() => {
		return {
			__html: lang('forgot_password_link_label'),
		}
	}, [lang])
	const [userData, setUserData] = useState({})
	const [passwordDisplay, setPasswordDisplay] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)

	const location = useLocation()
	const forgotPasswordSuccess = useMemo(() => {
		return new URLSearchParams(location.search).get('forgot-password-success')
	}, [location])

	const [
		shouldDisplayForgotPasswordSuccess,
		setShouldDisplayForgotPasswordSuccess,
	] = useState(false)
	useEffect(() => {
		setShouldDisplayForgotPasswordSuccess(!!forgotPasswordSuccess)
	}, [forgotPasswordSuccess])

	const apiHost = useMemo(() => getApiHost(), [])

	const mutation = useMutation(loginUser, {
		onSuccess: (data) => {
			queryClient.setQueryData('userInfos', {
				...data,
				...data.user_invitation_data,
			})
		},
	})

	const handleSubmit = async (e) => {
		setError(false)
		e.preventDefault()
		if (!isButtonDisabled()) {
			setLoading(true)
			try {
				const profile = await mutation.mutateAsync({
					email: userData.email,
					password: userData.password,
				})
				manageSigninOrSignup(profile, history)
			} catch (e) {
				setError(true)
				setLoading(false)
			}
		}
	}

	const isButtonDisabled = () => {
		const isEmail = userData.email && validateEmail(userData.email)
		return !isEmail
	}

	const [globalError, setGlobalError] = useState(null)
	useEffect(() => {
		const search = window.location.search.substring(1)
		const searchParams = new URLSearchParams(search)
		const errorParam = searchParams.get('error')
		if (errorParam) setGlobalError(lang(`errors.${errorParam}`))
	}, [setError])

	return (
		<div className={$BasePage.pageContent}>
			<div className={$BasePage.titleContainer}>
				<h1>{lang('login_title')}</h1>
			</div>
			<div className={$BasePage.formContainer}>
				{shouldDisplayForgotPasswordSuccess && (
					<>
						<div>{lang('forgot_password_success_message')}</div>
						<br />
					</>
				)}
				{globalError && (
					<div
						className={$.errorLabel}
						style={{ marginBottom: 12, padding: 0 }}
					>
						{globalError}
					</div>
				)}
				<a
					href={`${apiHost}/auth/signup/facebook${document.location.search}`}
					className={$.nudeLink}
				>
					<Button
						className="facebook-login"
						style={{ background: '#4267B2', border: 'none' }}
					>
						<img
							style={{ height: 24 }}
							src={SigninFacebookImage}
							alt={lang('login_facebook_button_label')}
						/>
					</Button>
				</a>
				<div style={{ marginBottom: 16 }}></div>
				<a
					href={`${apiHost}/auth/signup/google${document.location.search}`}
					className={$.nudeLink}
				>
					<Button
						className="google-login"
						style={{
							background: 'white',
							border: 'none',
						}}
					>
						<img
							style={{ height: 24 }}
							src={SigninGoogleImage}
							alt={lang('login_google_button_label')}
						/>
					</Button>
				</a>
				<div className={$.separator}>
					<div className={$.line}></div>
					<div className={$.or}>{lang('or')}</div>
					<div className={$.line}></div>
				</div>
				<form onSubmit={handleSubmit}>
					<Input
						placeholder={lang('email_address')}
						value={userData.email}
						onChange={(e) =>
							setUserData({ ...userData, email: e.target.value })
						}
						testId="email-input"
					/>
					<Input
						placeholder={lang('password')}
						value={userData.password}
						onChange={(e) =>
							setUserData({ ...userData, password: e.target.value })
						}
						suffix={
							passwordDisplay ? (
								<span onClick={() => setPasswordDisplay(false)}>
									<EyeOpened />
								</span>
							) : (
								<span onClick={() => setPasswordDisplay(true)}>
									<EyeClosed />
								</span>
							)
						}
						type={passwordDisplay ? 'text' : 'password'}
						testId="password-input"
					/>
					<div
						className={cn($.description, $.large, $.forgotPassword)}
						dangerouslySetInnerHTML={forgotPasswordText}
					></div>
					{error && <div className={$.errorLabel}>{lang('login_error')}</div>}
					<Button
						type="submit"
						className={$.freeAccountButton}
						loading={loading}
						disabled={isButtonDisabled()}
						testId="submit-button"
					>
						{lang('login_button_label')}
					</Button>
				</form>
			</div>
		</div>
	)
}

export default withLocale(LoginPage)
