import $ from './Sider.module.scss'
import DropLogo from '../../assets/images/drop-logo.svg'
// import NeutrinoIcon from '../../assets/images/icon-neutrino.svg'
import { withLocale } from '../../contexts/locale.context'
import { useHistory } from 'react-router-dom'

const Sider = ({ isLogin }) => {
	const history = useHistory()
	return (
		<div className={$.siderContainer}>
			<div
				className={$.dropLogo}
				onClick={() => history.push(isLogin ? '/login' : '/signup')}
			>
				<img src={DropLogo} alt="Drop" />
			</div>
			{!isLogin && <div className={$.menu}></div>}
		</div>
	)
}

export default withLocale(Sider)
