import { getApiHost } from '../platform'

export const getUser = (accessToken) => {
	const apiHost = getApiHost()
	return fetch(`${apiHost}/api/auth/user`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	})
}
