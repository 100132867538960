import { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import EmailIcon from '../../assets/icons/email'
import SignupGoogleImage from '../../assets/images/signup_google.png'
import ContinueWithFacebookImage from '../../assets/images/continue-with-facebook.png'
import MetaLogo from '../../assets/images/simplified-flow/meta-logo.png'
import { withLocale } from '../../contexts/locale.context'
import { getApiHost } from '../../platform'
import Button from '../common/Button'
import $ from './EmailStep.module.scss'
import $BasePage from '../page/BaseCssPage.module.scss'

const SignupStep = ({ lang, setCurrentStep }) => {
	const history = useHistory()

	useEffect(() => {
		setCurrentStep(1)
	}, [])

	const apiHost = getApiHost()

	const [globalError, setGlobalError] = useState(null)
	useEffect(() => {
		const search = window.location.search.substring(1)
		const searchParams = new URLSearchParams(search)
		const errorParam = searchParams.get('error')
		if (errorParam) setGlobalError(lang(`errors.${errorParam}`))
	}, [])

	const isSalesPage = useRouteMatch('/register')
	const handleProviderClick = (provider) => {
		setGlobalError(null)
		localStorage.removeItem('profile')
		window.location.replace(
			`${apiHost}/auth/signup/${provider}?from=${
				isSalesPage ? 'sales' : 'saas'
			}`,
		)
	}

	const title = isSalesPage ? lang('register_title') : lang('sign_up_title')
	const subtitle = isSalesPage
		? lang('register_subtitle')
		: lang('sign_up_subtitle')

	const emailPath = isSalesPage ? '/register/email' : '/signup/email'

	return (
		<div className={$BasePage.pageContent}>
			<div className={$BasePage.titleContainer}>
				<h1>{title}</h1>
				<div className={$BasePage.subtitle}>{subtitle}</div>
				{globalError && (
					<div className="errorLabel" style={{ marginTop: 20 }}>
						{globalError}
					</div>
				)}
			</div>
			<div className={$BasePage.formContainer}>
				<Button
					className="google-login"
					onClick={() => handleProviderClick('google')}
					style={{
						background: 'white',
						border: 'none',
						fontSize: '16px',
					}}
					testId={'signup-with-google-button'}
				>
					<img
						style={{ height: 24 }}
						src={SignupGoogleImage}
						alt={lang('sign_up_google_button_label')}
					/>
				</Button>
				<div style={{ marginBottom: 16 }}></div>
				<Button
					className="facebook-login"
					style={{ background: '#4267B2', border: 'none' }}
					onClick={() => handleProviderClick('facebook')}
				>
					<img
						style={{ height: 24 }}
						src={ContinueWithFacebookImage}
						alt={lang('login_facebook_button_label')}
					/>
				</Button>
				<div style={{ marginBottom: 16 }}></div>
				<Button
					className="email-login"
					onClick={() => history.push(emailPath)}
					style={{
						background:
							'linear-gradient(117.27deg, #0F1011 32.99%, rgba(15, 16, 17, 0) 96.45%)',
						border: '1px solid rgba(89, 90, 92, 0.32)',
						filter: ' drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.52))',
						color: 'white',
					}}
					icon={<EmailIcon />}
					testId={'signup-with-email-button'}
				>
					{lang('sign_up_email_button_label')}
				</Button>
			</div>
			<div className={$.MetaLogo}>
				<img src={MetaLogo} height={30} />
			</div>
		</div>
	)
}

export default withLocale(SignupStep)
