export const passwordStrength = (pw) => {
	return (
		/.{8,}/.test(pw) /* at least 8 characters */ *
		// /.{12,}/.test(pw)          /* bonus if longer */
		(+/[a-z]/.test(pw) /* a lower letter */ +
			/[A-Z]/.test(pw) /* a upper letter */ +
			/\d/.test(pw) /* a digit */ +
			/[^A-Za-z0-9]/.test(pw)) /* a special character */
	)
}

export const validateEmail = (email) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}
