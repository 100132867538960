import './Loader.scss'

const Loader = ({ color = 'inherit', size = 12 }) => {
	return (
		<div
			className="loader"
			style={{ borderTopColor: color, width: size + 'px', height: size + 'px' }}
		></div>
	)
}

export default Loader
