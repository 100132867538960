function AlertCircle() {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10 6.66602V9.99935M10 13.3327H10.0083M18.3333 9.99935C18.3333 14.6017 14.6024 18.3327 10 18.3327C5.39763 18.3327 1.66667 14.6017 1.66667 9.99935C1.66667 5.39698 5.39763 1.66602 10 1.66602C14.6024 1.66602 18.3333 5.39698 18.3333 9.99935Z"
				stroke="currentColor"
				strokeWidth="1.66667"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export default AlertCircle
