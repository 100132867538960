import { apiWithoutAuth } from '../helpers/http'

export const loginUser = (payload) =>
	apiWithoutAuth('api/auth/login', {
		method: 'POST',
		data: {
			email: payload.email,
			password: payload.password,
		},
	})

export const loginGoogleCallback = (code, state) =>
	apiWithoutAuth(`auth/login/google/callback?code=${code}&state=${state}`, {
		method: 'GET',
	})

export const loginFacebookCallback = (code, state) =>
	apiWithoutAuth(`auth/login/facebook/callback?code=${code}&state=${state}`, {
		method: 'GET',
	})
