import { useEffect } from 'react'
import SignupPage from './components/page/SignupPage'
import LoginPage from './components/page/LoginPage'
import ForgotPasswordPage from './components/page/ForgotPasswordPage'
import ForgotPasswordValidatePage from './components/page/ForgotPasswordValidatePage'
import SignupProviderCallbackPage from './components/page/SignupProviderCallbackPage'
import { Route, Redirect, Switch, useLocation } from 'react-router-dom'
import { useShopifyBehaviour } from './api/useShopifyBehaviour'
import PageContainer from './components/page/PageContainer'

const Routes = () => {
	const location = useLocation()

	useEffect(() => {
		window.analytics?.page()
	}, [location])

	useShopifyBehaviour()

	return (
		<Switch>
			<Route path="/:signupVerb(signup|register)/callback/:provider(google|facebook|shopify)">
				<PageContainer>
					<SignupProviderCallbackPage />
				</PageContainer>
			</Route>
			<Route path="/:signupVerb(signup|register)">
				<PageContainer>
					<SignupPage />
				</PageContainer>
			</Route>
			<Route path="/login">
				<PageContainer>
					<LoginPage />
				</PageContainer>
			</Route>
			<Route path="/forgot-password" exact>
				<PageContainer>
					<ForgotPasswordPage />
				</PageContainer>
			</Route>
			<Route path="/forgot-password/validate/:token">
				<PageContainer>
					<ForgotPasswordValidatePage />
				</PageContainer>
			</Route>
			<Route path="/" exact>
				<Redirect to="/login" />
			</Route>
		</Switch>
	)
}

export default Routes
