import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { providerSignupCallback } from '../../api/userSignUp'
import { withLocale } from '../../contexts/locale.context'
import GoogleIcon from '../../assets/images/logo-google.svg'
import FacebookLogo from '../../assets/icons/FacebookLogo'
import Button from '../common/Button'
import { manageSigninOrSignup } from './helpers/login'
import { useMutation, useQueryClient } from 'react-query'
import $BasePage from './BaseCssPage.module.scss'

const SignupProviderCallbackPage = ({ lang }) => {
	const history = useHistory()
	const queryClient = useQueryClient()
	const { provider } = useParams()

	const mutation = useMutation(providerSignupCallback, {
		onSuccess: (data) => {
			queryClient.setQueryData('userInfos', {
				...data,
				...data.user_invitation_data,
			})
		},
	})

	useEffect(async () => {
		try {
			const search = window.location.search.substring(1)
			const searchParams = new URLSearchParams(search)
			const code = searchParams.get('code')
			const state = searchParams.get('state') ?? ''
			if (!code) {
				history.replace('/signup')
				const error = new Error()
				error.code = 'bad-code'
				throw error
			}
			// Shopify provider does not provide back state
			if (!state) {
				history.replace('/signup')
				const error = new Error()
				error.code = 'bad-state'
				throw error
			}

			const invitationId = localStorage.getItem('invitation_id')
			const profile = await mutation.mutateAsync({
				provider,
				code: encodeURIComponent(code),
				state: encodeURIComponent(state),
				invitationId,
			})
			manageSigninOrSignup(profile, history, provider, state)
		} catch (e) {
			const errorCode = e.response?.data?.error || 'unknown'
			history.replace(`/signup?error=${errorCode}`)
		}
	}, [])

	return (
		<div className={$BasePage.pageContent}>
			<div className={$BasePage.formContainer}>
				{(() => {
					if (provider === 'google') {
						return (
							<Button
								variant="darkGradient"
								style={{
									background:
										'linear-gradient(175deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 98.96%),linear-gradient(38deg, rgba(232, 227, 227, 0.7) 3.59%, rgba(159, 159, 159, 0.7) 23.34%, rgba(179, 249, 167, 0.7) 36.53%, rgba(195, 194, 255, 0.7) 49.78%, rgba(228, 228, 228, 0.7) 61.48%, rgba(252, 251, 252, 0.7) 74.47%, rgba(255, 255, 255, 0.7) 84.8%, rgba(231, 231, 231, 0.7) 103.21%, rgba(246, 246, 246, 0.7) 120.31%, rgba(255, 255, 255, 0.7) 132.91%)',
									border: 'none',
								}}
								gradientBorder="linear-gradient(45deg, #E8E3E3 0%, #9F9F9F 25.28%, #B3F9A7 35.47%, #C3C2FF 45.71%, #E4E4E4 54.77%, #FCFBFC 64.81%, #FFFFFF 72.8%, #E7E7E7 87.03%, #F6F6F6 96.26%, #FFFFFF 100%)"
								className="google-login"
								icon={<img src={GoogleIcon} alt="Google" />}
								loading={true}
							>
								{lang('sign_up_google_button_label')}
							</Button>
						)
					} else if (provider === 'facebook') {
						return (
							<Button
								className="facebook-login"
								icon={<FacebookLogo />}
								style={{ background: '#1877F2', marginBottom: 18 }}
								loading={true}
								disabled
							>
								{lang('sign_up_facebook_button_label')}
							</Button>
						)
					} else if (provider === 'shopify') {
						return (
							<Button
								variant="darkGradient"
								style={{
									background:
										'linear-gradient(175deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.6) 98.96%),linear-gradient(38deg, rgba(232, 227, 227, 0.7) 3.59%, rgba(159, 159, 159, 0.7) 23.34%, rgba(179, 249, 167, 0.7) 36.53%, rgba(195, 194, 255, 0.7) 49.78%, rgba(228, 228, 228, 0.7) 61.48%, rgba(252, 251, 252, 0.7) 74.47%, rgba(255, 255, 255, 0.7) 84.8%, rgba(231, 231, 231, 0.7) 103.21%, rgba(246, 246, 246, 0.7) 120.31%, rgba(255, 255, 255, 0.7) 132.91%)',
									border: 'none',
								}}
								gradientBorder="linear-gradient(45deg, #E8E3E3 0%, #9F9F9F 25.28%, #B3F9A7 35.47%, #C3C2FF 45.71%, #E4E4E4 54.77%, #FCFBFC 64.81%, #FFFFFF 72.8%, #E7E7E7 87.03%, #F6F6F6 96.26%, #FFFFFF 100%)"
								className="shopify-login"
								loading={true}
							>
								Continuing with shopify
							</Button>
						)
					}
				})()}
			</div>
		</div>
	)
}

export default withLocale(SignupProviderCallbackPage)
