function InstagramLoader() {
	return (
		<svg
			style={{
				margin: 'auto',
				background: 'none',
				display: 'block',
				shapeRendering: 'auto',
			}}
			width="41px"
			height="41px"
			viewBox="0 0 100 100"
			preserveAspectRatio="xMidYMid"
		>
			<g transform="rotate(0 50 50)">
				<rect
					x="48.5"
					y="23"
					rx="0"
					ry="0"
					width="3"
					height="14"
					fill="currentColor"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="0.8s"
						begin="-0.7s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(45 50 50)">
				<rect
					x="48.5"
					y="23"
					rx="0"
					ry="0"
					width="3"
					height="14"
					fill="currentColor"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="0.8s"
						begin="-0.6s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(90 50 50)">
				<rect
					x="48.5"
					y="23"
					rx="0"
					ry="0"
					width="3"
					height="14"
					fill="currentColor"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="0.8s"
						begin="-0.5s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(135 50 50)">
				<rect
					x="48.5"
					y="23"
					rx="0"
					ry="0"
					width="3"
					height="14"
					fill="currentColor"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="0.8s"
						begin="-0.4s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(180 50 50)">
				<rect
					x="48.5"
					y="23"
					rx="0"
					ry="0"
					width="3"
					height="14"
					fill="currentColor"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="0.8s"
						begin="-0.3s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(225 50 50)">
				<rect
					x="48.5"
					y="23"
					rx="0"
					ry="0"
					width="3"
					height="14"
					fill="currentColor"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="0.8s"
						begin="-0.2s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(270 50 50)">
				<rect
					x="48.5"
					y="23"
					rx="0"
					ry="0"
					width="3"
					height="14"
					fill="currentColor"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="0.8s"
						begin="-0.1s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
			<g transform="rotate(315 50 50)">
				<rect
					x="48.5"
					y="23"
					rx="0"
					ry="0"
					width="3"
					height="14"
					fill="currentColor"
				>
					<animate
						attributeName="opacity"
						values="1;0"
						keyTimes="0;1"
						dur="0.8s"
						begin="0s"
						repeatCount="indefinite"
					></animate>
				</rect>
			</g>
		</svg>
	)
}
export default InstagramLoader
