import dev from './dev.json'
import staging from './staging.json'
import production from './production.json'
import e2e from './e2e.json'

export const isDev = () => window.location.href.indexOf('.crbt.ai') !== -1
export const isE2e = () =>
	window.location.href.indexOf('.e2e-usedrop.com') !== -1
export const isStaging = () =>
	window.location.href.indexOf('.staging-usedrop.com') !== -1
export const isProduction = () => !isDev() && !isStaging() && !isE2e()

const configuration = (() => {
	if (isDev()) {
		return dev
	} else if (isE2e()) {
		return e2e
	} else if (isStaging()) {
		return staging
	} else {
		return production
	}
})()

export default configuration
