import config from '../configuration'

const cookieName = 'dropprofile'

const saveCookie = (name, value, expires, domain, shouldSecure) => {
	// In production, secure the cookie
	const secure = shouldSecure ? ';secure' : ''
	const cookie = `${name}=${value};max-age=${expires};domain=${domain};path=/${secure}`
	document.cookie = cookie
}
export const createAndSaveProfileCookie = (profile) => {
	const cookieValue = JSON.stringify(profile)
	saveCookie(
		cookieName,
		cookieValue,
		profile.expires_in,
		config.COOKIE_DOMAIN,
		false,
	)
}

export const deleteProfileCookie = () => {
	document.cookie =
		cookieName +
		`=;domain=${config.COOKIE_DOMAIN};path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export const getProfileCookie = () => {
	const value = `; ${document.cookie}`
	const parts = value.split(`; ${cookieName}=`)
	if (parts.length === 2) {
		return JSON.parse(parts.pop().split(';').shift())
	}

	return null
}
