import { useState, useEffect, useCallback, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Button from '../common/Button'
import Input from '../common/Input'
import EyeClosed from '../../assets/icons/eyeclosed'
import EyeOpened from '../../assets/icons/eyeopened'
import { withLocale } from '../../contexts/locale.context'
import { registerUser } from '../../api/userSignUp'
import { passwordStrength, validateEmail } from '../../helpers/formTests'
import { manageSigninOrSignup } from '../page/helpers/login'
import cn from 'classnames'
import $ from './EmailStep.module.scss'
import $BasePage from '../page/BaseCssPage.module.scss'

const EmailStep = ({ lang, setCurrentStep, invitationId }) => {
	const isSalesPage = useRouteMatch('/register')
	const history = useHistory()
	const queryClient = useQueryClient()
	const legalTextWithLinks = () => {
		return {
			__html: lang('sign_up_legals'),
		}
	}

	const [userData, setUserData] = useState({})
	const [passwordDisplay, setPasswordDisplay] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)

	useEffect(() => {
		setCurrentStep(1)
	}, [])

	const isButtonDisabled = useMemo(() => {
		const isPasswordStrongEnough =
			userData.password && passwordStrength(userData.password) >= 4
		const isEmail = userData.email && validateEmail(userData.email)
		const isFullName = userData.fullName && userData.fullName.length > 5
		return !isEmail || !isPasswordStrongEnough || !isFullName
	}, [userData])

	const mutation = useMutation(registerUser, {
		onSuccess: (data) => {
			queryClient.setQueryData('userInfos', {
				...data,
				...data.user_invitation_data,
			})
		},
	})

	const postForm = async () => {
		if (!isButtonDisabled) {
			setLoading(true)
			try {
				setError(false)
				const { fullName, ...rest } = userData
				const profile = await mutation.mutateAsync({
					...rest,
					full_name: fullName,
					invitation_id: invitationId,
					from: isSalesPage ? 'sales' : 'saas',
				})
				manageSigninOrSignup(profile, history)
			} catch (e) {
				const error = e.response?.data?.error
				setLoading(false)
				setError(error)
			}
		}
	}

	const [globalError, setGlobalError] = useState(null)
	useEffect(() => {
		const search = window.location.search.substring(1)
		const searchParams = new URLSearchParams(search)
		const errorParam = searchParams.get('error')
		if (errorParam) setGlobalError(lang(`errors.${errorParam}`))
	}, [setError])

	const handlePasswordUpdate = useCallback(
		(value) => {
			setUserData({ ...userData, password: value })
			const isPasswordValid = value && passwordStrength(value) >= 4
			if (isPasswordValid) {
				setPasswordError(false)
			}
		},
		[userData],
	)
	const [passwordError, setPasswordError] = useState(false)
	const testPassword = useCallback(() => {
		const isPasswordValid =
			userData.password && passwordStrength(userData.password) >= 4
		if (!isPasswordValid) {
			setPasswordError(true)
		} else {
			setPasswordError(false)
		}
	}, [userData])

	const title = isSalesPage ? lang('register_title') : lang('sign_up_title')
	const subtitle = isSalesPage
		? lang('register_subtitle')
		: lang('sign_up_subtitle')

	return (
		<div className={$BasePage.pageContent}>
			<div className={$BasePage.titleContainer}>
				<h1>{title}</h1>
				<div className={$BasePage.subtitle}>{subtitle}</div>
				{globalError && (
					<div className="errorLabel" style={{ marginTop: 20 }}>
						{globalError}
					</div>
				)}
			</div>
			<div className={cn($BasePage.formContainer, $.email)}>
				<Input
					placeholder={lang('onboarding.inputs.full_name_placeholder')}
					value={userData.fullName}
					onChange={(e) =>
						setUserData({ ...userData, fullName: e.target.value })
					}
					testId="fullname-input"
				/>
				<Input
					placeholder={lang('email_address')}
					value={userData.email}
					onChange={(e) => setUserData({ ...userData, email: e.target.value })}
					testId="email-input"
				/>
				<Input
					placeholder={lang('password')}
					value={userData.password}
					onChange={(e) => handlePasswordUpdate(e.target.value)}
					suffix={
						passwordDisplay ? (
							<span onClick={() => setPasswordDisplay(false)}>
								<EyeOpened />
							</span>
						) : (
							<span onClick={() => setPasswordDisplay(true)}>
								<EyeClosed />
							</span>
						)
					}
					type={passwordDisplay ? 'text' : 'password'}
					onEnter={postForm}
					onBlur={() => testPassword()}
					error={passwordError}
					testId="password-input"
				/>
				{passwordError && (
					<div className={$.passwordError}>
						{lang('errors.password_strength')}
					</div>
				)}
				{error &&
					(Array.isArray(error) ? (
						error.map((err, index) => (
							<div key={index} className={$.errorLabel}>
								{err}
							</div>
						))
					) : (
						<div className={$.errorLabel}>{lang(`errors.${error}`)}</div>
					))}
				{error === 'account_already_created' && (
					<div
						className={$.loginFallback}
						onClick={() => history.push('/login')}
					>
						{lang('login_fallback')}
					</div>
				)}
				<div
					className={$.legal}
					dangerouslySetInnerHTML={legalTextWithLinks()}
				></div>
				<Button
					variant="gradient"
					className={$.freeAccountButton}
					onClick={() => postForm()}
					loading={loading}
					disabled={isButtonDisabled}
					testId="submit-button"
				>
					{lang('sign_up_create_account_button_label')}
				</Button>
			</div>
		</div>
	)
}

export default withLocale(EmailStep)
