import React from 'react'

function EyeOpened() {
	return (
		<svg
			width="18"
			height="17"
			viewBox="0 0 18 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 10.5C1 10.5 4.2 5 9 5C13.8 5 17 10.5 17 10.5C17 10.5 13.8 16 9 16C4.2 16 1 10.5 1 10.5Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.0001 12.8572C10.3256 12.8572 11.4001 11.8018 11.4001 10.5C11.4001 9.19821 10.3256 8.14288 9.0001 8.14288C7.67461 8.14288 6.6001 9.19821 6.6001 10.5C6.6001 11.8018 7.67461 12.8572 9.0001 12.8572Z"
				fill="currentColor"
			/>
			<path
				d="M9 5V1"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M4.56445 6.02319L2.01473 2.62516"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M13.3662 6.02319L15.9159 2.62516"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	)
}
export default EyeOpened
