import Routes from './Routes'
import { Locale } from './components/locale'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import './App.scss'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	},
})

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<Locale>
				<BrowserRouter>
					<Routes />
				</BrowserRouter>
			</Locale>
		</QueryClientProvider>
	)
}

export default App
