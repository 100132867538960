import { getApiHost } from '../platform'

export const getWorkspaces = (accessToken) => {
	const apiHost = getApiHost()
	return fetch(`${apiHost}/api/workspaces`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	})
}
