import get from 'lodash/get'
import { LocaleContext } from '../contexts/locale.context'
import en from '../locales/en.locale'
import fr from '../locales/fr.locale'

const translations = {
	en,
	fr,
}

const Locale = ({ children }) => {
	// const userLang = navigator.language || navigator.userLanguage
	// const regex = /[^-]+/
	// const lang = userLang.indexOf('-') !== 0 ? regex.exec(userLang)[0] : userLang
	// const currentLang = Object.keys(translations).includes(lang) ? lang : 'en'
	const currentLang = 'en'

	const t = (s) => get(translations[currentLang], s.toLowerCase(), s)

	return <LocaleContext.Provider value={t}>{children}</LocaleContext.Provider>
}

export { Locale }
