import { apiWithoutAuth, apiWithAuth } from '../helpers/http'

export const sendUserSignUp = (payload) =>
	apiWithoutAuth('api/signup/register', {
		method: 'POST',
		data: payload,
	})

export const registerUser = (payload) =>
	apiWithoutAuth('api/signup2/register', {
		method: 'POST',
		data: payload,
	})

export const createOrganizationAndWorkspace = (payload) =>
	apiWithAuth('signup2/setup', {
		method: 'POST',
		data: payload,
	})

export const updateUserInfos = (payload) =>
	apiWithAuth('signup2/setup', {
		method: 'POST',
		data: payload,
	})

export const createWorkspace = (code, instagramUsername) =>
	apiWithAuth(`signup/instagram/connect/${instagramUsername}?code=${code}`, {
		method: 'GET',
	})

export const providerSignupCallback = (payload) => {
	if (payload.provider === 'shopify') {
		return apiWithoutAuth(`api/auth/login-shopify?code=${payload.code}`, {
			method: 'GET',
			withCredentials: true,
		})
	}

	return apiWithoutAuth(
		`api/signup2/${payload.provider}/callback?code=${payload.code}&state=${
			payload.state
		}${payload.invitationId ? '&invitation_id=' + payload.invitationId : ''}`,
		{
			method: 'GET',
		},
	)
}

export const getInstagramAccountInfos = (instagramAccountName) =>
	apiWithAuth('instagram/infos', {
		method: 'POST',
		data: {
			account: instagramAccountName,
		},
	})

export const confirmInvitation = (invitationId) =>
	apiWithoutAuth(
		`api/v2/organizations/signup-invitations/verify/${invitationId}`,
		{
			method: 'GET',
		},
	)

export const refreshInfos = () =>
	apiWithAuth('auth/refresh', {
		method: 'GET',
	})

export const getLastStep = () =>
	apiWithAuth('signup/step', {
		method: 'GET',
	})

export const cancelNewWorkspaceCreation = () =>
	apiWithAuth('v2/users/me/workspace-creation-mode/cancel', {
		method: 'PUT',
	})

export const selectWorkspaceForShopifyInstallation = (workspaceId) =>
	apiWithAuth('signup2/shopify/select-workspace', {
		method: 'POST',
		data: { workspace_id: workspaceId },
	})

export const createNewWorkspaceForShopifyInstallation = () =>
	apiWithAuth('signup2/shopify/create-workspace', {
		method: 'POST',
		data: {},
	})

export const cancelShopifyInstallation = () =>
	apiWithAuth('signup2/shopify/cancel-workspace-selection', {
		method: 'POST',
		data: {},
	})
