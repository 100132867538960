import { useQuery } from 'react-query'
import { refreshInfos } from '../../../api/userSignUp'
import { withLocale } from '../../../contexts/locale.context'
import Button from '../../common/Button'
import { getProfile, setProfile } from '../../../helpers/profile'
import { createAndSaveProfileCookie } from '../../../api/cookies'
import { getDashboardUrl } from '../../../platform'
import $BasePage from '../../page/BaseCssPage.module.scss'

const CreatingWorkspaceStep = ({ lang }) => {
	useQuery('refresher', refreshInfos, {
		onSuccess: (data) => {
			if (data.access_token) setProfile(data)

			if (data.signup_step !== null) {
				return
			}

			const profile = getProfile()
			createAndSaveProfileCookie(profile)
			localStorage.removeItem('profile')
			localStorage.removeItem('invitation_id')
			window.location.href = getDashboardUrl(
				data.last_tenant_used,
				'?signupCompleted=true',
			)
		},
		refetchInterval: 1000,
	})

	return (
		<div className={$BasePage.pageContent}>
			<div className={$BasePage.formContainer}>
				<Button
					onClick={() => {
						/** noop */
					}}
					disabled={true}
					loading={true}
					testId="creating"
				>
					{lang('onboarding.step_simplified_creating_loader_label')}
				</Button>
			</div>
		</div>
	)
}

export default withLocale(CreatingWorkspaceStep)
