import { useMemo } from 'react'
import { refreshInfos } from '../../../api/userSignUp'
import { useQuery } from 'react-query'
import { getProfile, setProfile } from '../../../helpers/profile'

export const useUserInfos = () => {
	const profile = getProfile()

	const {
		data: userInfos,
		isLoading,
		isSuccess,
		isError,
	} = useQuery('userInfos', refreshInfos, {
		onSuccess: (payload) => {
			if (payload.access_token) setProfile(payload)
		},
		retry: 1,
		enabled: !!profile,
		staleTime: 2000,
	})

	const userInfosState = useMemo(
		() => ({
			isLoading,
			isSuccess,
			isError,
		}),
		[isLoading, isSuccess, isError],
	)

	return useMemo(
		() => ({
			userInfos,
			userInfosState,
		}),
		[userInfos, userInfosState],
	)
}
