import cn from 'classnames'
import { useMemo, useState } from 'react'
import { forgotPassword } from '../../api/userForgotPassword'
import { withLocale } from '../../contexts/locale.context'
import { validateEmail } from '../../helpers/formTests'
import Button from '../common/Button'
import Input from '../common/Input'
import $ from './Login.module.scss'
import $BasePage from './BaseCssPage.module.scss'

const ForgotPassword = ({ lang }) => {
	const signupText = useMemo(() => {
		return {
			__html: lang('back_to_login_link_label'),
		}
	}, [lang])
	const [email, setEmail] = useState('')
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (!isButtonDisabled()) {
			setLoading(true)
			try {
				await forgotPassword(email)
			} catch (e) {
				console.error(e)
			}
			setLoading(false)
			setSuccess(true)
		}
	}

	const isButtonDisabled = () => {
		const isEmail = email && validateEmail(email)
		return !isEmail || success
	}

	return (
		<div className={$BasePage.pageContent}>
			<div className={$BasePage.formContainer}>
				<h1>{lang('reset_your_password_title')}</h1>
				<br />
				<form onSubmit={handleSubmit}>
					<Input
						placeholder={lang('email_address')}
						value={email}
						onChange={(e) => {
							setEmail(e.target.value)
							setSuccess(false)
						}}
					/>
					<Button
						variant="primary"
						className={$.freeAccountButton}
						loading={loading}
						disabled={isButtonDisabled()}
						type="submit"
					>
						{lang('set_new_password_button_label')}
					</Button>
					<br />
					{success && (
						<>
							<div>{lang('forgot_password_email_sent')}</div>
							<br />
						</>
					)}
				</form>
				<div
					className={cn($.description, $.large)}
					dangerouslySetInnerHTML={signupText}
				></div>
			</div>
		</div>
	)
}

export default withLocale(ForgotPassword)
