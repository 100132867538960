import React, { useEffect, useState, useRef } from 'react'
import cn from 'classnames'
import $ from './Input.module.scss'

const InputWrapper = ({
	value = '',
	placeholder,
	onChange,
	className,
	disabled,
	type = 'text',
	prefix,
	suffix,
	maxLength,
	id,
	onKeyDown,
	onPaste,
	onEnter,
	onBlur,
	error,
	testId,
}) => {
	const [focused, setFocused] = useState(false)
	const inputRef = useRef()
	const handleKeyDown = (e) => {
		if (e.key === 'Enter' && onEnter) {
			onEnter()
		}
		if (onKeyDown) onKeyDown(e)
	}
	const [hasValue, setHasValue] = useState(false)
	useEffect(() => {
		if (inputRef?.current?.value) setHasValue(true)
	}, [])
	const handleChange = (e) => {
		onChange(e)
		if (!e.target.value) {
			setHasValue(false)
		}
	}
	return (
		<div className={$.inputWrapper}>
			{prefix && (
				<div
					className={cn($.prefix, {
						[$.visible]: focused || !!inputRef?.current?.value || hasValue,
					})}
				>
					{prefix}
				</div>
			)}
			<input
				data-testid={testId}
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={handleChange}
				className={cn($.input, $[className], {
					[$.hasValue]: focused || !!inputRef?.current?.value || hasValue,
					[$.hasError]: !!error,
					[$.hasPrefix]: !!prefix,
				})}
				disabled={disabled}
				onFocus={() => setFocused(true)}
				onBlur={() => {
					setFocused(false)
					if (onBlur) onBlur()
				}}
				ref={inputRef}
				maxLength={maxLength}
				id={id}
				onKeyDown={handleKeyDown}
				onPaste={onPaste}
				autoComplete="off"
			/>
			{placeholder && (
				<div
					className={cn($.placeholder, {
						[$.focused]: focused || !!inputRef?.current?.value || hasValue,
						[$.hasError]: !!error,
					})}
					onClick={() => inputRef.current.focus()}
				>
					{placeholder}
				</div>
			)}
			{suffix && (
				<div
					className={cn($.suffix, {
						[$.focused]: focused || !!inputRef?.current?.value || hasValue,
						[$.hasError]: !!error,
					})}
				>
					{suffix}
				</div>
			)}
		</div>
	)
}

export default InputWrapper
