const getParams = function (url) {
	const params = {}
	const parser = document.createElement('a')
	parser.href = url
	const query = parser.search.substring(1)
	const vars = query.split('&')
	for (let i = 0; i < vars.length; i++) {
		const pair = vars[i].split('=')
		params[pair[0]] = decodeURIComponent(pair[1])
	}
	return params
}

const toNumber = (number, locale = 'en-US', currency = 'USD') => {
	return new Intl.NumberFormat(locale, {
		currency,
	}).format(number)
}

const isInteger = (number) => {
	return number % 1 === 0
}

const transformNumberInK = (number) => {
	if (number === undefined || number === null) return 'N/A'
	if (number > 999999 || number < -999999) {
		if ((number / 1000) % 1 === 0) {
			const numberFormat = (number / 1000000).toFixed(0) + 'M'
			return numberFormat
		} else {
			const numberFormat = (number / 1000000).toFixed(1) + 'M'
			return numberFormat
		}
	} else if (number > 999 || number < -999) {
		if ((number / 1000) % 1 === 0) {
			const numberFormat = (number / 1000).toFixed(0) + 'K'
			return numberFormat
		} else {
			const numberFormat = (number / 1000).toFixed(1) + 'K'
			return numberFormat
		}
	} else {
		return isInteger(number) ? number : number.toFixed(1)
	}
}

export { getParams, toNumber, transformNumberInK }
