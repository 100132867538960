import cn from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import {
	createOrganizationAndWorkspace,
	getInstagramAccountInfos,
} from '../../../api/userSignUp'
import { withLocale } from '../../../contexts/locale.context'
import Button from '../../common/Button'
import Input from '../../common/Input'
import $ from './SimplifiedFlowStep.module.scss'
import debounce from 'lodash/debounce'
import AlertIcon from '../../../assets/icons/AlertCircle'
import InstagramLoader from '../../../assets/icons/InstagramLoader'
import { transformNumberInK } from '../../../helpers/utils'
import { getProfile, setProfile } from '../../../helpers/profile'
import { createAndSaveProfileCookie } from '../../../api/cookies'
import { getDashboardUrl } from '../../../platform'
import $BasePage from '../../page/BaseCssPage.module.scss'

const SimplifiedFlowStep = ({ userInfos, setCurrentStep, lang }) => {
	const [companyName, setCompanyName] = useState('')
	const [companyWebsite, setCompanyWebsite] = useState('')
	const [igHandle, setIgHandle] = useState('')
	const [loading, setLoading] = useState(false)
	const [igAccountLoading, setIgAccountLoading] = useState(false)
	const [error, setError] = useState(null)
	const [instagramAccountInfos, setInstagramAccountInfos] = useState(null)
	const [canByPassIgAccount, setCanByPassIgAccount] = useState(false)

	useEffect(() => {
		setCurrentStep(2)
	}, [])

	const handleCompanyNameChange = (e) => {
		setCompanyName(e.target.value)
	}
	const handleCompanyWebsiteChange = (e) => {
		setCompanyWebsite(e.target.value)
	}

	const handleIgHandleChange = (e) => {
		setIgHandle(e.target.value)
		setIgAccountLoading(true)
		handleInstagramUsername(e.target.value)
	}

	const isDisabled =
		!companyName ||
		!igHandle ||
		(!instagramAccountInfos && !canByPassIgAccount) ||
		igAccountLoading

	const mutation = useMutation(createOrganizationAndWorkspace, {
		onSuccess: (data) => {
			setProfile(data.refreshed_identity)
			const profile = getProfile()
			createAndSaveProfileCookie(profile)
			localStorage.removeItem('profile')
			localStorage.removeItem('invitation_id')
			window.location.href = getDashboardUrl(
				data.workspace_name,
				'?signupCompleted=true',
			)
		},
	})

	const createWorkspace = useCallback(async () => {
		if (isDisabled) return
		setLoading(true)
		try {
			setError(null)
			await mutation.mutateAsync({
				company_name: companyName,
				ig_handle: igHandle,
				company_website: companyWebsite ?? '',
			})
			setLoading(false)
		} catch (e) {
			const errorCode = e.response?.data?.error || 'unknown'
			setError(lang(`instagram.errors.${errorCode}`))
			setLoading(false)
		}
	}, [isDisabled, companyName, igHandle])

	const handleInstagramUsername = useMemo(() => {
		const debounced = debounce(async (instagramUsername) => {
			try {
				setCanByPassIgAccount(false)
				setError(null)
				if (!instagramUsername) {
					setIgAccountLoading(false)
					setInstagramAccountInfos(null)
					return
				}
				const instagramAccountInfos = await getInstagramAccountInfos(
					instagramUsername,
				)
				setInstagramAccountInfos(instagramAccountInfos)
				if (
					instagramAccountInfos?.status &&
					instagramAccountInfos.status !== 'ok'
				) {
					setError(lang(`instagram.errors.${instagramAccountInfos?.status}`))
				} else {
					localStorage.setItem(
						'instagram_infos',
						JSON.stringify(instagramAccountInfos),
					)
				}
				setError(null)
				setIgAccountLoading(false)
			} catch (e) {
				setIgAccountLoading(false)
				setInstagramAccountInfos(null)
				const errorCode = e.response?.data?.error
				setError(lang(`instagram.errors.${errorCode}`))
				if (
					errorCode === 'unknown_error_while_fetching_instagram_informations'
				) {
					setCanByPassIgAccount(true)
				}
			}
		}, 1000)

		return (account) => {
			setIgAccountLoading(true)
			debounced(account)
		}
	}, [userInfos, setIgAccountLoading, setInstagramAccountInfos, setError, lang])

	return (
		<div className={$BasePage.pageContent}>
			<div className={$BasePage.titleContainer}>
				<h1>{lang('onboarding.user_name_title')}</h1>
			</div>
			<div className={$BasePage.formContainer}>
				<Input
					placeholder={
						lang('onboarding.inputs.company_name_placeholder') + ' *'
					}
					value={companyName}
					onChange={(e) => handleCompanyNameChange(e)}
					testId="company-name-input"
				/>
				<Input
					placeholder={lang('onboarding.inputs.company_website_placeholder')}
					value={companyWebsite}
					onChange={(e) => handleCompanyWebsiteChange(e)}
					testId="company-website-input"
				/>
				<Input
					placeholder={
						lang('onboarding.inputs.instagram_name_placeholder') + ' *'
					}
					prefix="@"
					value={igHandle}
					onChange={(e) => handleIgHandleChange(e)}
					testId="instagram-username-input"
				/>
				{error && (
					<div className={$.errorBlock}>
						<div className={$.errorIcon}>
							<AlertIcon />
						</div>
						<div className={$.errorContent}>
							<div className={$.errorDescription}>{error}</div>
						</div>
					</div>
				)}
				<div
					className={cn($.instagramInfos, {
						[$.noData]: !instagramAccountInfos || !instagramAccountInfos?.name,
						[$.isLoading]: igAccountLoading,
					})}
					data-testid="instagram-data-component"
				>
					<div className={$.leftContent}>
						<div className={$.logoWrapper}>
							<div className={$.logo}>
								{igAccountLoading ? (
									<div className={$.instagramLoader}>
										<InstagramLoader />
									</div>
								) : (
									instagramAccountInfos?.profile_picture_url && (
										<img src={instagramAccountInfos.profile_picture_url} />
									)
								)}
							</div>
						</div>
					</div>
					<div className={$.rightContent}>
						<div
							className={$.instagramName}
							data-testid="instagram-data-component_name"
							data-teststate={igAccountLoading ? 'loading' : 'idle'}
						>
							{igAccountLoading
								? lang('instagram.data.loading')
								: instagramAccountInfos?.name || lang('instagram.data.brand')}
						</div>
						<div className={$.dataItemContainer}>
							<div
								className={$.dataItem}
								data-testid="instagram-data-component_followers-count"
								data-teststate={igAccountLoading ? 'loading' : 'idle'}
							>
								<span className={$.number}>
									{igAccountLoading
										? lang('instagram.data.no_data')
										: instagramAccountInfos?.followers_count
										? transformNumberInK(instagramAccountInfos.followers_count)
										: lang('instagram.data.no_data')}
								</span>
								<span className={$.label}>
									{' '}
									{lang('instagram.data.followers')}
								</span>
							</div>
							<div
								className={$.dataItem}
								data-testid="instagram-data-component_follows-count"
								data-teststate={igAccountLoading ? 'loading' : 'idle'}
							>
								<span className={$.number}>
									{igAccountLoading
										? lang('instagram.data.no_data')
										: instagramAccountInfos?.follows_count
										? transformNumberInK(instagramAccountInfos.follows_count)
										: lang('instagram.data.no_data')}
								</span>
								<span className={$.label}>
									{' '}
									{lang('instagram.data.following')}
								</span>
							</div>
						</div>
					</div>
				</div>
				<Button
					onClick={() => createWorkspace()}
					disabled={isDisabled || loading}
					loading={loading}
					testId="submit-button"
					style={{ marginTop: '20px' }}
				>
					{loading
						? lang('onboarding.step_simplified_button_label_loading')
						: lang('onboarding.step_simplified_button_label')}
				</Button>
				{loading && (
					<div
						style={{
							opacity: 0.7,
							color: 'white',
							paddingTop: '10px',
							fontSize: '14px',
						}}
					>
						{lang('onboarding.step_simplified_creating_label')}
					</div>
				)}
			</div>
		</div>
	)
}

export default withLocale(SimplifiedFlowStep)
