import { withLocale } from '../../contexts/locale.context'
import $ from './PageContainer.module.scss'
import { Link } from 'react-router-dom'

import Zigouigoui1 from '../../assets/images/simplified-flow/zigouigoui1.png'
import Zigouigoui2 from '../../assets/images/simplified-flow/zigouigoui2.png'
import Zigouigoui3 from '../../assets/images/simplified-flow/zigouigoui3.png'
import Zigouigoui4 from '../../assets/images/simplified-flow/zigouigoui4.png'
import Zigouigoui5 from '../../assets/images/simplified-flow/zigouigoui5.png'
import QuotePicture from '../../assets/images/simplified-flow/quote-picture.png'
import Rectangles from '../../assets/images/simplified-flow/rectangles.png'
import Brand1 from '../../assets/images/simplified-flow/brand1.png'
import Brand2 from '../../assets/images/simplified-flow/brand2.png'
import Brand3 from '../../assets/images/simplified-flow/brand3.png'
import Brand4 from '../../assets/images/simplified-flow/brand4.png'
import Brand5 from '../../assets/images/simplified-flow/brand5.png'
import Brand6 from '../../assets/images/simplified-flow/brand6.png'

export const PageContainer = ({ children }) => {
	const isLoginScreen = window.location.pathname.startsWith('/login')
	return (
		<div className={$.PageContainer}>
			<div className={$.Header}>
				<div className={$.Logo}>
					<svg
						width="72"
						height="19"
						viewBox="0 0 72 19"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M65.5882 8.69807H60.5375V3.55466H65.5882C66.9806 3.55466 68.1134 4.70838 68.1134 6.12637C68.1134 7.54435 66.9806 8.69807 65.5882 8.69807ZM65.5891 0H60.5385H59.3729H56.6533V18.991H60.5385V12.265H65.5891C69.2799 12.265 71.9995 9.51943 71.9995 6.1325C71.9995 2.74557 69.2799 0 65.5891 0Z"
							fill="white"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M7.76929 15.4331H3.88415V3.5638H7.76929C10.9826 3.5638 13.5969 6.22603 13.5969 9.49845C13.5969 12.7709 10.9826 15.4331 7.76929 15.4331ZM7.77028 0.00915527H3.88514H2.33106H0L0 19.0001H2.33106H3.88514H7.77028C13.2094 19.0001 17.4831 14.7488 17.4831 9.50461C17.4831 4.26041 13.2094 0.00915527 7.77028 0.00915527Z"
							fill="white"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M44.2524 18.991H47.746V0H44.2524V18.991Z"
							fill="white"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M53.8244 14.9512L51.3541 17.4691L38.1792 4.0405L40.6495 1.52264L53.8244 14.9512Z"
							fill="white"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M36.6836 11.2726H55.3158V7.71179H36.6836V11.2726Z"
							fill="white"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M51.3541 1.52264L53.8244 4.0405L40.6495 17.4691L38.1792 14.9512L51.3541 1.52264Z"
							fill="white"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M28.9427 8.70717H23.6979V3.56376H28.9427C30.3351 3.56376 31.468 4.71748 31.468 6.13546C31.468 7.55345 30.3351 8.70717 28.9427 8.70717ZM35.3542 6.14159C35.3542 2.75454 32.6346 0.00909424 28.9437 0.00909424H23.6989H22.7275H19.8137V19H23.6989V12.2741H26.867L30.6802 19H35.1664L31.1524 11.9197C33.669 11.0745 35.3542 8.80716 35.3542 6.14159Z"
							fill="white"
						/>
					</svg>
				</div>
				<div className={$.Link}>
					<Link to={isLoginScreen ? '/signup' : '/login'}>
						{isLoginScreen
							? `Don't have an account? Signup for free`
							: 'Already have an account? Login'}
					</Link>
				</div>
			</div>
			<div className={$.Container}>
				<div className={$.SubContainer}>
					<div className={$.Left}>{children}</div>
					<div className={$.Right}>
						<div className={$.RightHeader}>
							<img
								src={Zigouigoui1}
								alt="Zigouigoui1"
								className={$.Zigouigoui1}
							/>
							<img
								src={Zigouigoui2}
								alt="Zigouigoui2"
								className={$.Zigouigoui2}
							/>
							<img
								src={Zigouigoui3}
								alt="Zigouigoui3"
								className={$.Zigouigoui3}
							/>
							<img
								src={Zigouigoui4}
								alt="Zigouigoui4"
								className={$.Zigouigoui4}
							/>
							<img
								src={Zigouigoui5}
								alt="Zigouigoui5"
								className={$.Zigouigoui5}
							/>
							<h1>
								Turn your Followers into{' '}
								<span className={$.Emphase}>Customers</span>
							</h1>
						</div>
						<div className={$.RightContent}>
							<div className={$.HorizontalPart}>
								<div className={$.QuotePictureContainer}>
									<img src={QuotePicture} alt="Quote picture" />
								</div>
								<div className={$.Quote}>
									“Drop{"'"}s platform is a{' '}
									<span className={$.Emphase}>game-changer</span>. We have
									experienced first-hand how it fuels engagement and sales,
									transforming followers into a highly engaged community.”
									<div className={$.Pills}>
										<div className={$.Pill1}>LOUIS MARTY</div>
										<div className={$.Pill2}>CEO AT MERCI HANDY</div>
									</div>
								</div>
							</div>
						</div>
						<div className={$.RightFooter}>
							<div className={$.Brands}>
								<img src={Brand6} alt="Brand6" className={$.Brand6} />
								<img src={Brand4} alt="Brand4" className={$.Brand4} />
								<img src={Brand1} alt="Brand1" className={$.Brand1} />
								<img src={Brand5} alt="Brand5" className={$.Brand5} />
								<img src={Brand2} alt="Brand2" className={$.Brand2} />
								<img src={Brand3} alt="Brand3" className={$.Brand3} />
							</div>
							<div className={$.Rectangles}>
								<div className={$.Img}>
									<img
										src={Rectangles}
										alt="Rectangles"
										className={$.Rectangles}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default withLocale(PageContainer)
