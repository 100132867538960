import { getUser } from './user'
import { deleteProfileCookie, getProfileCookie } from './cookies'
import { getDashboardUrlWithRedirectParams } from '../platform'
import { useEffect } from 'react'

export const useShopifyBehaviour = () => {
	useEffect(() => {
		;(async () => {
			const queryParams = new URLSearchParams(document.location.search)

			const shopifyData = queryParams.get('shopify_data')
			if (!shopifyData) {
				return
			}

			const profile = getProfileCookie()
			if (profile) {
				try {
					const userRaw = await getUser(profile.access_token)
					const user = await userRaw.json()
					if (user) {
						window.location.href = getDashboardUrlWithRedirectParams(
							user.last_tenant_used,
						)
					}
				} catch (e) {
					deleteProfileCookie()
				}
			}
		})()
	}, [])
}
