import cn from 'classnames'
import Loader from './Loader'
import $ from './Button.module.scss'

const ButtonWrapper = ({
	onClick,
	variant,
	children,
	className,
	disabled,
	loading,
	icon,
	suffixIcon,
	style,
	gradientBorder,
	testId,
}) => {
	return (
		<div className={$.buttonWrapper} style={{ background: gradientBorder }}>
			<button
				onClick={onClick}
				disabled={disabled}
				className={cn(className, $[className], $.button, $[variant])}
				style={style}
				data-testid={testId}
				data-teststate={loading ? 'loading' : 'idle'}
			>
				{loading ? (
					<div className={$.icon}>
						<Loader />
					</div>
				) : (
					icon && <div className={$.icon}>{icon}</div>
				)}
				{children}
				{suffixIcon && <div className={$.suffixIcon}>{suffixIcon}</div>}
			</button>
		</div>
	)
}

export default ButtonWrapper
