import { apiWithoutAuth } from '../helpers/http'

export const forgotPassword = (email) =>
	apiWithoutAuth('api/auth/forgot', {
		method: 'POST',
		data: {
			email,
		},
	})

export const validateForgotPassword = (
	email,
	password,
	passwordConfirmation,
	token,
) =>
	apiWithoutAuth('api/auth/reset', {
		method: 'POST',
		data: {
			email,
			password,
			token,
			password_confirmation: passwordConfirmation,
		},
	})
