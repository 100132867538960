import { useState, useMemo, useEffect, useCallback } from 'react'
import { validateForgotPassword } from '../../api/userForgotPassword'
import { withLocale } from '../../contexts/locale.context'
import Sider from '../sider/Sider'
import Button from '../common/Button'
import Input from '../common/Input'
import EyeClosed from '../../assets/icons/eyeclosed'
import EyeOpened from '../../assets/icons/eyeopened'
import $ from './Login.module.scss'
import cn from 'classnames'
import { passwordStrength } from '../../helpers/formTests'
import { useHistory, useLocation, useParams } from 'react-router-dom'

const ForgotPasswordValidatePage = ({ lang }) => {
	const signupText = useMemo(() => {
		return {
			__html: lang('dont_have_account'),
		}
	}, [lang])
	const [password, setPassword] = useState('')
	const [passwordConfirmation, setPasswordConfirmation] = useState('')
	const { token } = useParams()
	const location = useLocation()
	const history = useHistory()
	const email = useMemo(() => {
		return new URLSearchParams(location.search).get('email').replace(/ /g, '+')
	}, [location])
	const [passwordDisplay, setPasswordDisplay] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)

	useEffect(() => {
		if (!email) {
			history.replace('/login')
		}
	}, [email])

	const isButtonDisabled = useMemo(() => {
		const isPasswordStrongEnough = password && passwordStrength(password) >= 4
		const isConfirmationSame = password === passwordConfirmation
		return !isConfirmationSame || !isPasswordStrongEnough
	}, [password, passwordConfirmation])

	const handleSubmit = async (e) => {
		setError(false)
		e.preventDefault()
		if (!isButtonDisabled) {
			setLoading(true)
			try {
				await validateForgotPassword(
					email,
					password,
					passwordConfirmation,
					token,
				)
				history.push('/login?forgot-password-success=true')
			} catch (e) {
				setError(true)
				setLoading(false)
			}
		}
	}

	const handlePasswordUpdate = useCallback((value) => {
		setPassword(value)
		const isPasswordValid = value && passwordStrength(value) >= 4
		if (isPasswordValid) {
			setPasswordError(false)
		}
	}, [])

	const [passwordError, setPasswordError] = useState(false)
	const testPassword = useCallback(() => {
		const isPasswordValid = password && passwordStrength(password) >= 4
		if (!isPasswordValid) {
			setPasswordError(true)
		} else {
			setPasswordError(false)
		}
	}, [password])

	return (
		<div className={$.stepContainer}>
			<div className={$.formContainer}>
				<h1>{lang('set_your_new_password_title')}</h1>
				<br />
				<form onSubmit={handleSubmit}>
					<Input
						placeholder={lang('forgot_password_password_placeholder')}
						value={password}
						onChange={(e) => handlePasswordUpdate(e.target.value)}
						suffix={
							passwordDisplay ? (
								<span onClick={() => setPasswordDisplay(false)}>
									<EyeOpened />
								</span>
							) : (
								<span onClick={() => setPasswordDisplay(true)}>
									<EyeClosed />
								</span>
							)
						}
						type={passwordDisplay ? 'text' : 'password'}
						onBlur={() => testPassword()}
						error={passwordError}
					/>
					{passwordError && (
						<div className={$.passwordError}>
							{lang('errors.password_strength')}
						</div>
					)}
					<Input
						placeholder={lang(
							'forgot_password_password_confirmation_placeholder',
						)}
						value={passwordConfirmation}
						onChange={(e) => setPasswordConfirmation(e.target.value)}
						type={passwordDisplay ? 'text' : 'password'}
					/>
					{error && (
						<div className={$.errorLabel}>{lang('forgot_password_error')}</div>
					)}
					<Button
						variant="primary"
						className={$.freeAccountButton}
						loading={loading}
						disabled={isButtonDisabled}
						type="submit"
					>
						{lang('set_new_password_button_label')}
					</Button>
				</form>
				<div
					className={cn($.description, $.large)}
					dangerouslySetInnerHTML={signupText}
				></div>
			</div>
		</div>
	)
}

export default withLocale(ForgotPasswordValidatePage)
