import { createAndSaveProfileCookie } from '../../../api/cookies'
import { identifyIntercomUser } from '../../../helpers/intercom'
import { setProfile } from '../../../helpers/profile'
import { getDashboardUrlWithRedirectParams } from '../../../platform'

export const manageSigninOrSignup = (profile, history, provider, state) => {
	window.analytics?.identify(profile.user?.uuid, {
		email: profile.user?.email,
	})

	if (profile?.signup_step) {
		identifyIntercomUser(profile.user?.email, profile.user?.uuid)
		setProfile(profile)
		history.push(`/signup/${profile.signup_step}`)
	} else {
		createAndSaveProfileCookie(profile)

		const tenantToRedirect = profile?.last_tenant_used

		if (tenantToRedirect) {
			location.replace(getDashboardUrlWithRedirectParams(tenantToRedirect))
		} else {
			history.replace('/login?error=no_redirect_tenant_found')
		}
	}
}
