import config from '../src/configuration'

export const getApiHost = () => config.CENTRAL_API_URL
export const getDashboardUrl = (tenant, params) => {
	// override the redirected workspace if in query parameter
	return config.TENANT_TEMPLATE_URL.replace('central', tenant) + (params ?? '')
}
export const getDashboardUrlWithRedirectParams = (workspaceToRedirectTo) => {
	const query = new URLSearchParams(document.location.search)
	const workspace = query.get('workspace') || workspaceToRedirectTo
	query.delete('workspace')
	const path = query.get('path') || ''
	query.delete('path')

	// Here we are cleaning up the query parameters with uncessary google and facebook redirect values
	query.delete('code')
	query.delete('state')

	const queryParams = query.toString() ? `?${query.toString()}` : ''

	return (
		config.TENANT_TEMPLATE_URL.replace('central', workspace) +
		path +
		queryParams
	)
}
